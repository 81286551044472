import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import OtherLayout from '../components/OtherLayout'
import SEO from '../components/SEO'
import Descriptions from "../components/Descriptions"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import Video from "../components/Video"
import logo from '../img/logo.png'
import { FaMapMarkerAlt, FaMobileAlt, FaEnvelope, FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa"

export class PpcMainPageTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: ''
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    alert("Here is Contact Form"
      + "\nFirstName: " + this.state.firstName
      + "\nLastName: " + this.state.lastName
      + "\nCompany: " + this.state.company
      + "\nEmail: " + this.state.email
      + "\nMessage: " + this.state.message
    );
  }
  render() {
    const { seo, hero, services, industries, philosophy, about, feedback, contact } = this.props
    return (
      <div className="ppc-main-page">
        <SEO title={seo.title} description={seo.description} />
        <div
          style={{
            backgroundImage: `url(${
              !!hero.background.image.childImageSharp ? hero.background.image.childImageSharp.fluid.src : hero.background.image
              })`,
            backgroundPosition: `center center`,
            backgroundSize: 'cover',
            alignItems: "start"
          }}
        >
          <div className="container">
            <Link to="/" title="Logo">
              <img src={logo} alt="Kaldi" style={{ padding: 10, width: 300 }} />
            </Link>
            <div className="columns">
              <div className="column is-6">
                <h1
                  style={{
                    color: 'white',
                    lineHeight: '1',
                    fontWeight: "800",
                    fontSize: "4rem",
                    marginTop: 100
                  }}
                >
                  {hero.title}<br />
                  {hero.secondtitle}
                </h1>
                <div style={{ fontSize: "1.5rem", margin: "2rem 0" }}>
                  <Descriptions descriptions={hero.descriptions} color="white" />
                </div>
                <button style={{ background: "#8dc643", borderColor: "#8dc643", fontSize: "1.3rem", padding: "10px 2rem" }}>{hero.buttontxt}</button>
              </div>
              <div className="column is-6" style={{ marginTop: 100 }}>
                <PreviewCompatibleImage imageInfo={hero.image} />
              </div>
            </div>
          </div>

        </div>
        <section>
          <div
            style={{
              backgroundImage: `url(${
                !!services.background.image.childImageSharp ? services.background.image.childImageSharp.fluid.src : services.background.image
                })`,
              backgroundPosition: `center center`,
              backgroundSize: 'cover',
              alignItems: "start",
              paddingTop: 100
            }}
          >
            <div className="container">
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  color: 'white',
                  lineHeight: '1',
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                {services.title}
              </h1>
              <div className="borderbar" ></div>
              <div className="columns is-multiline" style={{ padding: "4rem 0" }}>
                {
                  services.list.map((service, i) => {
                    return (
                      <div className="column is-6">
                        <div style={{ borderRadius: 10, background: "white", padding: "2rem", minHeight: "400px" }}>
                          <div style={{ width: 100 }} >
                            <PreviewCompatibleImage imageInfo={service.image} />
                          </div>
                          <a href={service.url} style={{ color: "black" }}>
                            <h2
                              className="has-text-weight-bold is-size-5-mobile is-size-4-tablet is-size-4-desktop is-size-4-widescreen"
                              style={{ lineHeight: "1.5", margin: "1.5rem 0" }}
                            >
                              {service.title}
                            </h2>
                          </a>
                          <p>
                            {service.description}
                          </p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </section>
        <section>
          <div style={{ alignItems: "start", paddingTop: 100 }} >
            <div className="container">
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  lineHeight: '1',
                  fontWeight: "800",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {industries.title}
              </h1>
              <div className="borderbar" ></div>
              <div className="columns is-multiline" style={{ padding: "4rem 0" }}>
                {
                  industries.list.map((industry, i) => {
                    return (
                      <div className="column is-3">
                        <div className="industrybox">
                          <div style={{ width: 100, margin: "auto" }} >
                            <PreviewCompatibleImage imageInfo={industry.image} />
                          </div>
                          <a href={industry.url} style={{ color: "black" }}>
                            <p
                              className="has-text-weight-bold is-size-4-mobile"
                              style={{ lineHeight: "1.5", margin: "1.5rem 0", textAlign: "center" }}
                            >
                              {industry.title}
                            </p>
                          </a>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </section>
        <section>
          <div style={{ alignItems: "start", paddingTop: 100 }} >
            <div className="container">
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  lineHeight: '1',
                  fontWeight: "800",
                  color: "black",
                }}
              >
                {philosophy.title}
              </h1>
              <div className="borderbar" style={{ marginLeft: 0 }}></div>
              <div className="columns is-multiline" style={{ padding: "4rem 0" }}>
                <div className="column is-6">
                  <p style={{ lineHeight: "2", width: "90%", fontSize: "1.2rem", fontWeight: '600' }}>{philosophy.description1}</p>
                  <PreviewCompatibleImage imageInfo={philosophy.image} />
                </div>
                <div className="column is-6">
                  <p style={{ lineHeight: "2", fontSize: "1.2rem", marginBottom: 50 }}>{philosophy.description2}</p>
                  <button style={{ background: "#8dc643", borderColor: "#8dc643", fontSize: "1.3rem", padding: "10px 2rem" }}>{philosophy.buttontxt}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ background: "#28363d", padding: "100px 0" }}>
          <div style={{ alignItems: "start" }} >
            <div className="container">
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  color: 'white',
                  lineHeight: '1',
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                {about.title}
              </h1>
              <div className="borderbar" ></div>
              <p style={{ color: "white", fontSize: "1.5rem", fontWeight: "600", textAlign: "center", padding: "1rem 0" }}>
                {about.description}
              </p>
              <div className="video">
                <Video
                  videoSrcURL={about.videourl}
                  videoTitle={about.videoTitle}
                />
              </div>
            </div>
          </div>
        </section>
        <section style={{ background: "#53c5d1", padding: "100px 0" }}>
          <div style={{ alignItems: "start" }} >
            <div className="container">
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  color: 'white',
                  lineHeight: '1',
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                {feedback.title}
              </h1>
              <div className="borderbar" style={{ borderColor: "#28363d", marginBottom: "3rem" }} ></div>
              <div className="columns">
                {
                  feedback.list.map((item, i) => {
                    return (
                      <div className="column is-4">
                        <div className="feedbackbox">
                          <div style={{ width: 100, margin: "auto", marginBottom: "1rem" }}>
                            <PreviewCompatibleImage imageInfo={feedback.image} />
                          </div>
                          <p style={{ lineHeight: "2", fontWeight: "600", textAlign: "center" }}>
                            "{item.description}"
                          </p>
                          <div style={{ width: 100, margin: "auto", marginTop: 50 }}>
                            <PreviewCompatibleImage imageInfo={item.image} />
                          </div>
                          <p style={{ fontSize: "1.5rem", fontWeight: "800", textAlign: "center", padding: "1rem 0" }}>
                            {item.client}
                          </p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            style={{
              backgroundImage: `url(${
                !!contact.image.childImageSharp ? contact.image.childImageSharp.fluid.src : contact.image
                })`,
              backgroundPosition: `center center`,
              backgroundSize: 'cover',
              alignItems: "start",
              padding: "10rem 0 5rem"
            }}
          >
            <div className="container">
              <div className="columns">
                <div className="column is-7">
                  <h1
                    className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                    style={{
                      color: 'white',
                      lineHeight: '1',
                      fontWeight: "800",
                    }}
                  >
                    {contact.title}
                  </h1>
                  <div style={{ color: "white", marginTop: "3rem" }}>
                    <p>
                      <span style={{ color: "#8dc643", marginRight: "1rem" }}><FaMobileAlt /></span>
                      <span>{contact.phone}</span>
                    </p>
                    <p style={{ marginTop: "1rem" }}>
                      <span style={{ color: "#8dc643", marginRight: "1rem" }}><FaEnvelope /></span>
                      <span>{contact.email}</span>
                    </p>
                    <p style={{ marginTop: "1rem" }}>
                      <span style={{ color: "#8dc643", marginRight: "1rem" }}><FaMapMarkerAlt /></span>
                      <span>{contact.address[0]}</span>
                    </p>
                    <p style={{ margin: "1rem 2rem" }}>
                      <span>{contact.address[1]}</span>
                    </p>
                    <p style={{ margin: "1rem 2rem" }}>
                      <span>{contact.address[2]}</span>
                    </p>
                    <p style={{ margin: "2rem" }}>
                      <Link to={contact.twitter}>
                        <span className="social" ><FaTwitter /></span>
                      </Link>
                      <Link to={contact.facebook}>
                        <span className="social"><FaFacebookF /></span>
                      </Link>
                      <Link to={contact.linkedin}>
                        <span className="social"><FaLinkedinIn /></span>
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="column is-5">
                  <div className="contact-form">
                    <form
                      name="ppc-main-lp"
                      method="post"
                      action="/thanks"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type={'text'}
                            name={'firstName'}
                            onChange={this.handleChange}
                            id={'firstName'}
                            required={true}
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type={'text'}
                            name={'lastName'}
                            onChange={this.handleChange}
                            id={'lastName'}
                            required={true}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type={'email'}
                            name={'email'}
                            onChange={this.handleChange}
                            id={'email'}
                            required={true}
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type={'text'}
                            name={'company'}
                            onChange={this.handleChange}
                            id={'company'}
                            required={true}
                            placeholder="Company"
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <textarea
                            className="textarea"
                            name={'message'}
                            onChange={this.handleChange}
                            id={'message'}
                            required={true}
                            placeholder="Message..."
                          />
                        </div>
                      </div>
                      <div className="field">
                        <button type="submit">
                          Get a Free Consultation
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

PpcMainPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  services: PropTypes.object,
  industries: PropTypes.object,
  philosophy: PropTypes.object,
  about: PropTypes.object,
  feedback: PropTypes.object,
  contact: PropTypes.object,
}

const PpcMainPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <OtherLayout>
      <PpcMainPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        services={frontmatter.services}
        industries={frontmatter.industries}
        philosophy={frontmatter.philosophy}
        about={frontmatter.about}
        feedback={frontmatter.feedback}
        contact={frontmatter.contact}
      />
    </OtherLayout>
  )
}

PpcMainPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PpcMainPage

export const pageQuery = graphql`
  query PpcMainPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ppc-main-lp-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          title
          secondtitle
          descriptions
          buttontxt
          buttonurl
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          background {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        services {
          title
          background {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          list {
            title
            description
            url
            image {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        industries {
          title
          list {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        philosophy {
          title
          description1
          description2
          image {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttontxt
          buttonurl
        }
        about {
          title
          description
          videourl
          videoTitle
        }
        feedback {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          list {
            client
            description
            image {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        contact {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          phone
          email
          address
          twitter
          linkedin
          facebook
        }
      }
    }
  }
`

